import _ from 'lodash';
window._ = _;

// import 'bootstrap';
// import '../../public/css/stwtrade-css/sass/main.sass';
import '../../public/css/styles/main.sass';
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from "laravel-echo"

// const Pusher = require('pusher-js');

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    cluster: 'eu',
    key: '011f6094a6c454acdbdf',
    // wsHost: window.location.hostname,
    // wsPort: 6001,
    // wssPort: 6001,
    // forceTLS: false,
    // disableStats: true,
    // enabledTransports: ['ws', 'wss']
});
// window.Pusher = new Pusher(process.env.MIX_PUSHER_APP_KEY, {
//     cluster: 'us2',
//     // authEndpoint: process.env.APP_URL+'/broadcasting/auth',
//     authEndpoint: process.env.MIX_APP_URL + '/broadcasting/auth',
//     auth: {
//         headers: {
//             'X-CSRF-TOKEN': '{{ csrf_token() }}',
//         }
//     }
// });

// window.Pusher = require( 'pusher-js' );

// import Echo from "laravel-echo";


// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     wsHost: window.location.hostname,
//     wsPort: 6001,
//     forceTLS: false,
//     disableStats: true,
//     enabledTransports: ['ws', 'wss'],
// });